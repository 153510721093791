<template>
	<div class="documents-dashboard__item-subcategory">
		<div
			class="documents-dashboard__item-subcategory-header-wr"
			:class="{'active': expanded || hasDraggable}"
			@click.stop="toggleExpanded"
			@mouseenter="checkDraggable"
			@mouseleave="hovered = false"
		>
			<div class="documents-dashboard__item-subcategory-header-title">
				{{ data.name }}
			</div>
			<div
				v-if="hovered || showOptions"
				class="documents-dashboard__item-subcategory-header-options-wr"
			>
				<div
					class="documents-dashboard__item-subcategory-header-options-icon"
					:class="{'active': showOptions}"
					@click.stop="showOptions = !showOptions"
				>
					<Icon name="more" size="14" />
				</div>
				<div
					v-if="showOptions"
					v-click-outside="closeOptions"
					class="documents-dashboard__item-subcategory-header-options"
					@click.stop
				>
					<div
						class="documents-dashboard__item-subcategory-header-options-item"
						@click.stop="optionEditSubcategoryOpen = true, hovered = false, showOptions = false"
					>
						<Icon name="edit" size="14" />
						<span>
							Редактировать
						</span>
					</div>
					<div
						class="documents-dashboard__item-subcategory-header-options-item documents-dashboard__item-subcategory-header-options-item--delete"
						@click.stop="showDeleteContext = true, hovered = false, showOptions = false"
					>
						<Icon name="delete" size="14" />
						<span>
							Удалить
						</span>
					</div>
				</div>
			</div>
			<div
				class="documents-dashboard__item-subcategory-header-arrow"
				:class="{'active': expanded || hasDraggable}"
			>
				<Icon name="arrow-down" size="12" />
			</div>
		</div>
		<div v-if="hasDraggable || expanded" style="padding: 15px 0">
			<Container
				:data-index="containerIndex"
				group-name="column"
				:get-child-payload="itemIndex => getSubcategoryChildPayload(itemIndex)"
				:should-accept-drop="shouldAcceptSubcategoryDrop"
				drag-class="card-ghost-drop"
				:drop-placeholder="dropPlaceholderOptions"
				@drop="onSubcategoryDrop(groupIndex, containerIndex, $event)"
			>
				<component
					:is="dragType"
					v-for="subcategory in data.knowledges"
					:key="`${subcategory.id}-${containerIndex}`"
					:class="{'smooth-dnd-undraggable-wrapper': !isAdmin}"
				>
					<ListItem
						:checked="subcategory.checked"
						:is-new="subcategory.is_new"
						:id="subcategory.id"
						:link="subcategory.link"
						:title="subcategory.title"
						:tags="subcategory.tags"
						:is-expanded="isExpanded"
						:can-edit="userCanEdit"
						@deleteItem="deleteItem(groupIndex, data.id, $event)"
						@checkItem="checkItem"
					/>
				</component>
			</Container>
		</div>

		<DeleteContext v-if="showDeleteContext" @close="closeDeleteContext" @delete="deleteSubcategory(groupIndex, containerIndex)">
			<p>
				Удалить подкатегорию '{{ data.name }}'
			</p>
		</DeleteContext>
		<Editor
			v-if="optionEditSubcategoryOpen"
			header="Редактировать подкатегорию"
			:valueLength="30"
			:value="{ name: data.name }"
			@cancel="closeEditor"
			@submit="updateCategoryName"
		/>
	</div>
</template>

<script>
// import components
import { Container, Draggable } from 'vue-smooth-dnd';
import ListItem                 from '@/components/documents/dashboard-item/item.vue';
import Icon                     from '@/components/ui/icon/Icon.vue';
import DeleteContext           	from '@/components/context-menu/repeatable/DeleteContext.vue';
import Editor        			from '@/modules/settings/views/directions/components/Editor.vue';
import axios 					from 'axios';
import { MAIN_API_HOST } 		from '@/services/helpers/constants';
import { getCookie } 			from '@/composables/cookie.ts';


export default {
	name: 'Subcategory',

	props: ['data', 'groupIndex', 'containerIndex', 'userCanEdit', 'isExpanded', 'isAdmin', 'knowsCount', 'expandedList'],

	components:
	{
		Container,
		Draggable,
		ListItem,
		Icon,
		DeleteContext,
		Editor,
	},

	data()
	{
		return {
			optionEditSubcategoryOpen: false,
			sourceContainerOptions:    false,
			showDeleteContext: 		   false,
			isOpenInDragbble: 		   false,
			hasDraggable: 			   false,
			showOptions:			   false,
			expanded: 				   true,
			hovered: 				   false,

			dropPlaceholderOptions: {
				className: 'drop-preview',
				animationDuration: '100',
				showOnTop: true,
			},
		};
	},

	methods:
	{
		closeEditor()
		{
			this.optionEditSubcategoryOpen = false;
		},

		updateCategoryName(value)
		{
			if (value.name)
			{
				axios.put(`${MAIN_API_HOST}/subcategories/${this.data.id}`, { name: value.name })
				this.optionEditSubcategoryOpen = false
				this.$emit('subcategoryUpdate')
			}
		},

		getSubcategoryChildPayload(itemIndex)
		{
			return this.data.knowledges[itemIndex];
		},

		shouldAcceptSubcategoryDrop(sourceContainerOptions)
		{
			if(sourceContainerOptions.groupName === 'column')
			{
				this.sourceContainerOptions = true;
				return true;
			}
			else
			{
				this.sourceContainerOptions = false;
			}
		},

		onSubcategoryDrop(groupIndex, subcategoryIndex, dropResult)
		{
			this.$emit('onSubcategoryDrop', { groupIndex, subcategoryIndex, dropResult })
		},

		deleteItem(groupIndex, subcategoryIndex, event)
		{
			this.$emit('deleteSubcategoryItem', { groupIndex, subcategoryIndex, event })
		},

		deleteSubcategory(groupIndex, subcategoryIndex)
		{
			this.$emit('deleteSubcategory', { groupIndex, subcategoryIndex })
		},

		checkDraggable()
		{
			this.hovered = true;
			if(this.sourceContainerOptions ===  true)
			{
				if (document.querySelector('.smooth-dnd-no-user-select') !== null)
					this.hasDraggable = true;
			}
		},

		toggleExpanded() {
			if (this.hasDraggable) {
				this.expanded = false;
				this.hasDraggable = false;
				return;
			}
			this.expanded = !this.expanded;

			const expandList = getCookie(`expandedList-${this.$route.path.split('/').pop()}`)?.split(',').map(Number) || [];
			if (this.expanded && !expandList.includes(this.data.id)) {
				expandList.push(this.data.id);
			} else if (!this.expanded) {
				const index = expandList.indexOf(this.data.id);
				if (index !== -1) expandList.splice(index, 1);
			}

			document.cookie = `expandedList-${this.$route.path.split('/').pop()}=${expandList.join(',')}; path=/;`;

			const expandInfo = {
				expanded: this.expanded,
				id: this.data.id,
			};
			this.$emit('expandList', expandInfo);
		},

		closeOptions()
		{
			this.showOptions = false;
		},

		closeDeleteContext()
		{
			this.showDeleteContext = false;
		},

		checkItem(value)
		{
			this.$emit('checkItem', { subcategoryId: this.data.id, knowledgeId: value });
		},

		setExpand() {
			const expandList = getCookie(`expandedList-${this.$route.path.split('/').pop()}`)?.split(',').map(Number) || [];

			if (expandList.includes(this.data.id)) {
				this.expanded = true;
			} else if (this.knowsCount < 15) {
				this.expanded = true;
			} else {
				this.expanded = false;
			}
		},

	},
	computed:
	{
		// DND карточек
		// dragType() { return !this.isAdmin ? 'div' : Draggable },

		dragType() { return 'div' },
	},
	watch:
	{
		knowsCount()
		{
			this.setExpand()
		},
	},
	mounted() {
		this.setExpand();
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard__item-subcategory
	{
		.smooth-dnd-container
		{
			.smooth-dnd-draggable-wrapper,
			.smooth-dnd-undraggable-wrapper
			{ overflow: visible!important; padding: 7.5px 0; }
			.smooth-dnd-undraggable-wrapper .documents-dashboard__item-knowledge
			{ cursor: pointer }
		}
	}

	.documents-dashboard__item-subcategory-header-wr
	{
		cursor: pointer;
		padding-bottom: 10px;
		border-bottom: 0.5px solid transparent;
		display: flex;
		align-items: center;
		gap: 10px;

		&.active { border-bottom: 0.5px solid #4F5152; }
	}

	.card-ghost-drop
	{
		padding: 8px 12px;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}

	.documents-dashboard__item-subcategory-header-title
	{
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: left;
		color: #FFFFFF;
		word-break: break-all;
		max-width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
		line-clamp: 1;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.documents-dashboard__item-subcategory-header-options-wr { position: relative; }

	.documents-dashboard__item-subcategory-header-options-icon
	{
		height: 14px;
		color: #656565;

		&:hover { color: #FFFFFF }
		&.active { color: #FFFFFF }
	}

	.documents-dashboard__item-subcategory-header-options
	{
		cursor: auto;
		position: absolute;
		z-index: 30;
		width: max-content;
		padding: 20px;
		background: #191919;
		border: 0.7px solid #FFFFFF33;
		border-radius: 10px;
		top: 25px;
		left: -10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.documents-dashboard__item-subcategory-header-arrow
	{
		margin-left: auto;
		height: 14px;
		transition: all .2s ease-in-out;

		&.active { transform: rotate(180deg); }
	}

	.documents-dashboard__item-subcategory-header-options-item
	{
		cursor: pointer;
		display: flex;
		font-weight: 300;
		gap: 12px;
		line-height: 17px;
		font-size: 14px;

		&:hover
		{
			span { color: #FFFFFF; }
			.icon { color: #FFFFFF; }
		}

		&--delete
		{
			span { color: #9E4835; }
			.icon { color: #9E4835; }

			&:hover
			{
				span { color: red; }
				.icon { color: red; }
			}
		}
	}
</style>