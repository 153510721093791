export const setCookie = (name: any, value: any, options: any = {}) => {
  options = {
    path: '/',
    ...options,
  };
  if (options.expires instanceof Date) 
    options.expires = options.expires.toUTCString();
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true)
      updatedCookie += '=' + optionValue;
  }
  document.cookie = updatedCookie;
}

export const  getCookie = (name: any) => {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const rmCookie = (name: string) => {
  if( getCookie(name) )
    setCookie(name, '', { 'max-age': -1 }) 
}