import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';

@Module({
    dynamic: true,
    name: StoreModules.Check,
    namespaced: true,
    store,
})

class CheckModule extends VuexModule
{
  checked = false;

  get hasChecked()
  {   
    return this.checked
  }
  
  @Mutation
  setChecked(isPinned: boolean)
  {
    this.checked = isPinned
  }
}

export default getModule(CheckModule);