<template>
  <div class="closed-popup-wr">
    <div class="popup-wr__box">
      <div class="popup-wr__header">Уведомление</div>
      <div class="popup-wr__content">
        Вы бездействовали более 3-х часов. Знание «{{ knowlageTitle }}» закрыто и сохранено.
      </div>
      <button @click="closePopup" class="close-popup">Закрыть</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    knowlageTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
  },
}
</script>
<style lang="scss">
.closed-popup-wr {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: absolute;
  inset: 0;
  background-color: #141414cc;
}

.popup-wr__box {
  width: 451px;
  height: 238px;
  padding-block: 25px 33px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin: auto;
  inset: 0;
  background-color: #222222;
  border-radius: 20px;
}

.popup-wr__header { 
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  margin-bottom: 31px;
}

.popup-wr__content {
  max-width: 384px;
  position: relative;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 39px;
}

.close-popup {
  width: 107px;
  height: 41px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  cursor: pointer;
  border-radius: 20.5px;
  border: none;
  color: #000000;
  background-color: #FFD645;
}
 
</style>